<template>
    <div id="letter-container" :style="{ transitionDelay: !color ? '0s' : `${0.15 + 0.3 * (placement - 1)}s`, animationDelay: `${0.3 * (placement - 1)}s` }" :class="{ 
        'has-letter': letter,
        validated: color, 
        smaller: hasSmallerLetters,
        correct: color === 'correct', 
        partial: color === 'partial', 
        incorrect: color === 'incorrect',
        'no-animation': !animate,
    }">
        <div class="letter" v-if="letter" :style="{ transitionDelay: `${0.15 + 0.3 * (placement - 1)}s`}">
            {{ letter }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'LetterContainer',
    props: {
        letter: String,
        placement: Number,
        color: String,
        animate: Boolean,
        hasSmallerLetters: Boolean,
    },
}
</script>

<style lang="sass" scoped>
#letter-container
    width: 38px // 32px
    height: 38px
    background: url(~@/assets/img/letters-backgrounds/white.png) no-repeat center center
    background-color: white
    background-size: cover
    border-radius: 4px
    box-sizing: border-box
    margin: 4px
    transition: transform 0.1s, background 0.1s, border 0.1s
    user-select: none
    box-shadow: 0px 0px 8px rgba(0,0,0,0.5)
    @media (max-width: 380px)
        width: 38px
        height: 38px
    @media (max-width: 300px)
        width: 24px
        height: 24px
    @media (max-height: 700px)
        width: 34px
        height: 34px
    @media (max-height: 540px)
        width: 24px
        height: 24px
        border-radius: 3px
    &.has-letter
        border-color: #646464
    &.validated
        animation: flip
        animation-duration: 0.5s
        animation-timing-function: ease-in-out
        animation-fill-mode: forwards
        .letter
            transition: all 0.1s
            transform: rotateX(180deg)
            color: white
            text-shadow: 0px 0px 8px rgba(0,0,0,1)
    &.correct
        background: url(~@/assets/img/letters-backgrounds/green.png) no-repeat center center
        background-size: cover
        border-color: #00B200
        background-color: #00B200
    &.partial
        background: url(~@/assets/img/letters-backgrounds/yellow.png) no-repeat center center
        background-size: cover
        border-color: #F8C50B
        background-color: #F8C50B
    &.incorrect
        background: url(~@/assets/img/letters-backgrounds/blue.png) no-repeat center center
        background-size: cover
        border-color: #055CF0
        background-color: #055CF0
    &.no-animation
        transition: none
        animation: none
        transform: none
        .letter
            transition: none
            transform: none
    &.smaller
        width: 28px
        height: 28px
        .letter
            font-size: 24px
        @media (max-width: 380px)
            margin: 2px
        @media (max-height: 540px)
            width: 18px
            height: 18px
            margin: 2px
            .letter
                font-size: 18px
        @media (max-width: 300px)
            width: 24px
            height: 24px
    .letter
        width: 100%
        height: 100%
        color: black
        display: flex
        align-items: center
        justify-content: center
        font-weight: bolder
        font-size: 28px
        position: relative
        z-index: 3
        font-family: 'Ferveur Compact Italic'
        @media (max-height: 580px)
            padding-top: 3px
            font-size: 24px

    @keyframes flip
        from
            transform: rotateX(0deg)
        to
            transform: rotateX(180deg)
</style>