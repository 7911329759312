<template>
    <button id="key" @click="handleClick"
        :class="[{
            big,
            correct: color === 'correct', 
            partial: color === 'partial', 
            incorrect: color === 'incorrect',
        }, isBig && keyboardName]"
    >
        <div v-if="isBig">
            <img :src="`/icons/${keyContent.toLowerCase()}.svg`" :alt="keyContent" class="icon" />
        </div>
        <template v-else>
            {{ keyContent }}
        </template>
    </button>
</template>

<script>

export default {
    name: 'Key',
    props: {
        keyContent: String,
        color: String,
        keyboardName: String
    },
    data() {
        return {
            big: false,
        }
    },
    mounted() {
        if (this.keyContent === 'Entrer' || this.keyContent === 'Suppr') {
            this.big = true;
        }
    },
    computed: {
        isBig() {
            return ['Entrer','Suppr'].includes(this.keyContent);
        }
    },
    methods: {
        handleClick() {
            this.$emit('update:handleClick', this.keyContent);
        }
    }
}
</script>

<style lang="sass" scoped>
#key
    width: 34px
    height: 34px
    cursor: pointer
    margin: 2px
    font-size: 18px
    font-weight: 700
    color: #FFFFFF
    background: #00002D
    border-radius: 4px
    border: none
    user-select: none
    -webkit-tap-highlight-color: transparent
    @media (max-height: 700px)
        height: 46px
    &:active
        background: #4D4D4D
    &.big
        width: 72px
        &.qwerty, &.qwertz
            width: 66px
    &.correct
        background: #00B200
    &.partial
        background: #F8C50B
    &.incorrect
        background: #055CF0
    .icon
        height: 13px
        transform: translateY(1px)
    @media (max-width: 500px)
        width: 34px
        height: 52px
        &.big
            width: 72px
            &.qwerty, &.qwertz
                width: 53px
    @media (max-width: 510px)
        width: 32px
        height: 32px
        &.big
            width: 68px
            &.qwerty, &.qwertz
                width: 50px
    @media (max-width: 372px)
        width: 28px
        height: 28px
        &.big
            width: 60px
            &.qwerty, &.qwertz
                width: 44px
    @media (max-width: 320px)
        width: 22px
        height: 22px
        &.big
            width: 48px
            &.qwerty, &.qwertz
                width: 35px
    @media (max-height: 645px)
        height: 22px
</style>