<template>
    <div id="game" :class="`background-${todaysBackground}`">
        <header>
            <div class="header-container">
                <div class="icon-btn help" @click="helpOpened = true" title="Règles">
                    <img class="icon" src="~@/assets/icons/rules.png" alt="Règles" />
                </div>
                <img class="logo" src="~@/assets/img/parionssport-logo.png"/>
                <div class="icon-btn share" @click="statsOpened = !statsOpened" title="Partager" v-if="finished">
                    <img class="icon" src="~@/assets/icons/gift.png" alt="Partager" />
                </div>
                <div class="icon-btn share" v-else>

                </div>
            </div>
        </header>
        <main>
            <transition name="fade">
                <div class="error" v-if="error">{{ error }}</div>
            </transition>
            <div class="top">
                <div class="intro closed" v-if="isClosed">
                    <h1>Le jeu est fermé</h1>
                </div>
                <div class="intro" v-if="!isClosed">
                    <h1><strong>Parier</strong> c'est ...</h1>
                </div>
                <div class="grid" v-if="!isClosed">
                    <div class="attempt" v-for="attempt, indexA in attempts" :key="indexA" :class="{ shake: error && indexA === currentAttempt - 1 }">
                        <LetterContainer 
                            :letter="attempts[indexA][indexL]" 
                            :color="results[indexA][indexL]" 
                            :placement="letter" 
                            :animate="animateLetter" 
                            :hasSmallerLetters="hasSmallerLetters"
                            v-for="letter, indexL in nbLetters" 
                            :key="letter" />
                    </div>
                </div>
                <div class="sentence" v-if="!isClosed">
                    <p>Trouve le mot du jour et partage ton résultat pour tenter de</p>
                    <strong>remporter des cadeaux !</strong>
                </div>
            </div>
            <div class="keyboard" v-if="!isClosed">
                <div class="keyboard-line" v-for="line, index in keyboard.content" :key="index">
                    <Key
                        :keyContent="key"
                        :color="getKeyColor(key)"
                        v-for="key in line"
                        :key="key"
                        :keyboardName="keyboard.name"
                        @update:handleClick="handleKeyClick($event)"
                    />
                </div>
            </div>
            <transition name="fadeup">
                <div class="help-modal" v-if="helpOpened" :class="`background-${todaysBackground}`">
                    <div class="help-modal-content">
                        <div class="close-btn" @click="helpOpened = false">
                            <img class="icon" src="~@/assets/icons/close.png" alt="Fermer" />
                        </div>
                        <h2>Comment jouer ?</h2>
                        <div class="help-content">
                            <p>Chaque jour, tu dois deviner le mot qui complète la phrase "Parier c'est..." en 5 essais.</p>
                            <p>À chaque essai, les lettres du mot que tu as proposé changeront de couleur en fonction du code couleur ci-dessous.</p>
                            <div class="help-exemple">
                                <div class="help-word">
                                    <div class="help-letter-container correct">
                                        S
                                    </div>
                                    <div class="help-letter-container">
                                        P
                                    </div>
                                    <div class="help-letter-container">
                                        O
                                    </div>
                                    <div class="help-letter-container">
                                        R
                                    </div>
                                    <div class="help-letter-container">
                                        T
                                    </div>
                                </div>
                                <p>La lettre <span class="correct">S</span> est dans le mot, à la bonne place.</p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        P
                                    </div>
                                    <div class="help-letter-container">
                                        A
                                    </div>
                                    <div class="help-letter-container partial">
                                        R
                                    </div>
                                    <div class="help-letter-container">
                                        I
                                    </div>
                                    <div class="help-letter-container">
                                        S
                                    </div>
                                </div>
                                <p>La lettre <span class="partial">R</span> est dans le mot, mais pas à la bonne place.</p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        G
                                    </div>
                                    <div class="help-letter-container">
                                        A
                                    </div>
                                    <div class="help-letter-container">
                                        G
                                    </div>
                                    <div class="help-letter-container">
                                        N
                                    </div>
                                    <div class="help-letter-container incorrect">
                                        E
                                    </div>
                                </div>
                                <p>La lettre <span>E</span> n'est pas dans le mot.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fadeup">
                <div class="endgame-modal" v-if="statsOpened" :class="`background-${todaysBackground}`">
                    <div class="endgame-modal-content" v-bind:class="{ 'finished' : finished}">
                        <div class="close-btn" @click="statsOpened = false">
                            <img class="icon" src="~@/assets/icons/close.png" alt="Fermer" />
                        </div>
                        <div class="soluce" v-if="finished">
                            <h2><strong>Parier</strong> c'est ...</h2>
                            <h2 class="word-of-the-day" v-if="won">{{ wordOfTheDay }}</h2>
                            <h2 class="word-of-the-day" v-else>Dommage,</h2>
                            <p v-if="!won" class="too-bad">Reviens demain pour retenter ta chance</p>
                            <div class="btn sh4re-btn-anti-adblock" @click="sh4reAntiAdblock">
                                <p>{{resultsCopied ? 'Copié !' : 'Partager'}}</p>
                                <img class="icon" src="~@/assets/icons/twitter.svg" />
                            </div>
                        </div>
                        <div class="gifts" v-if="won">
                            <p>Partage sur Twitter ton résultat pour tenter de</p>
                            <p class="todays-gift">Remporter {{ this.todaysGift }} !</p>
                            <p class="precisions" v-if="giftInFrance">* Uniquement en france</p>
                            <p>Retiens le mot du jour, il te sera demandé en cas de victoire.</p>
                        </div>
                        <div class="no-gifts" v-else>

                        </div>
                        <div>
                            <div class="next-in">Prochain mot dans</div>
                            <div class="time">{{ countdownToNextWord }}</div>
                        </div>
                    </div>
                </div>
            </transition>
        </main>
        <footer>
            <div class="rules">
                <img class="legal-age-icon" src="~@/assets/icons/18ans.png"/>
                <a class="rules-link" href="https://www.fdj.fr/files/Reglement%20Twitter%202022.pdf" target="_blank">Règlement disponible ici</a>
                <div></div>
            </div>
            <div class="legal">
                <p class="mentions-legales">jouer comporte des risques : dépendance, isolement... appelez le 09 74 75 13 13 (appel non surtaxé)</p>
            </div>
        </footer>
    </div>
</template>

<script>
import moment from 'moment-timezone';

import LetterContainer from "./grid/LetterContainer.vue";
import Key from "./keyboard/Key.vue";
import words5Letters from "../assets/json/5-letters-words.json";
import words6Letters from "../assets/json/6-letters-words.json";
import words7Letters from "../assets/json/7-letters-words.json";
import words8Letters from "../assets/json/8-letters-words.json";
import words9Letters from "../assets/json/9-letters-words.json";

moment.locale('fr')
moment.tz.setDefault('Europe/Paris')

const FIRST_DAY = moment("2022-03-14T00:00:00");
const NB_ATTEMPTS = 5;
const PLAYABLE_DAYS = [
    "2022-03-14T00:00:00",
    "2022-03-15T00:00:00",
    "2022-03-16T00:00:00",
    "2022-03-17T00:00:00",
    "2022-03-18T00:00:00",
    "2022-03-19T00:00:00",
    "2022-03-20T00:00:00",
]
const KEYBOARD = {
    name: 'azerty',
    content: [
        ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
        ['Entrer', 'W', 'X', 'C', 'V', 'B', 'N', 'Suppr'],
    ],
};

export default {
    name: 'Game',
    components: {
        LetterContainer,
        Key,
    },
    data() {
        return {
            NB_ATTEMPTS,
            PLAYABLE_DAYS,
            keyboard: KEYBOARD,
            today: moment(),
            nbLetters: 5,
            words5Letters,
            words6Letters,
            words7Letters,
            words8Letters,
            words9Letters,
            todaysGift: null,
            giftInFrance: false,
            todaysBackground: 1,
            attempts: [],
            results: [],
            currentAttempt: 1,
            countdownToNextWord: '',
            wordOfTheDay: '',
            error: '',
            hasSmallerLetters: false,
            correctLetters: [],
            partialLetters: [],
            incorrectLetters: [],
            won: false,
            finished: false,
            statsOpened: false,
            helpOpened: false,
            animateLetter: true,
            resultsCopied: false,
            isClosed: false,
            userResults: {
                nbGames: 0,
                nbWins: 0,
                currentStreak: 0,
                bestStreak: 0,
                games: [],
            },
        }
    },
    mounted() {
        // Update timer to next word
        setInterval((function () {
            let duration = moment.duration(this.today.clone().endOf('day').diff(moment()))
            this.countdownToNextWord = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
            if (duration.as('milliseconds') < 0)
                this.countdownToNextWord = '00:00:00'

        }).bind(this), 1000)

        window.addEventListener('keydown', event => {
            if (/^[a-zA-Z]$/.test(event.key)) {
                this.handleKeyClick(event.key.toUpperCase());
            } else if (event.key === 'Enter') {
                this.handleKeyClick('Entrer');
            } else if (event.key === 'Backspace') {
                this.handleKeyClick('Suppr');
            }
        });

        for (let i = 0; i < NB_ATTEMPTS; i++) {
            this.attempts.push([]);
            this.results.push(new Array(5));
        }
        this.getWordOfTheDay();
        this.getSavedData();
    },
    methods: {
        setLSItem (key, val) {
            if (this.archivesMode && !['colorBlindMode', 'keyboard', 'sharedLink'].includes(key))
                return
            
            if (typeof val !== 'string')
                val = JSON.stringify(val)

            localStorage.setItem(key, val)
        },
        resetGridData () {
            this.attempts = [];
            this.results = [];
            this.correctLetters = [];
            this.partialLetters = [];
            this.incorrectLetters = [];
            this.won = false;
            this.finished = false;
            this.currentAttempt = 1;

            for (let i = 0; i < NB_ATTEMPTS; i++) {
                this.attempts.push([]);
                this.results.push(new Array(5));
            }

        },
        async getWordOfTheDay() {
            const date = this.today;
            const formatedDate = date.format('YYYY-M-D');
            let index;
            switch (formatedDate) {
                case '2022-3-14':
                    index = 3767;
                    this.nbLetters = 5;
                    this.wordOfTheDay = this.words5Letters[index];
                    this.todaysGift = "une console";
                    this.todaysBackground = 1;
                    break;
                case '2022-3-15':
                    index = 28528;
                    this.nbLetters = 7;
                    this.wordOfTheDay = this.words7Letters[index];
                    this.todaysGift = "des places de match*";
                    this.giftInFrance = true;
                    this.todaysBackground = 1;
                    break;
                case '2022-3-16':
                    index = 8632;
                    this.nbLetters = 6;
                    this.wordOfTheDay = this.words6Letters[index];
                    this.todaysGift = "des places de concert*";
                    this.giftInFrance = true;
                    this.todaysBackground = 2;
                    break;
                case '2022-3-17':
                    index = 25449;
                    this.nbLetters = 7;
                    this.wordOfTheDay = this.words7Letters[index];
                    this.todaysGift = "100€ de crédit Uber Eats";
                    this.todaysBackground = 2;
                    break;
                case '2022-3-18':
                    index = 41382;
                    this.nbLetters = 9;
                    this.hasSmallerLetters = true;
                    this.wordOfTheDay = this.words9Letters[index];
                    this.todaysGift = "une montre connectée";
                    this.todaysBackground = 3;
                    break;
                case '2022-3-19':
                    index = 11963;
                    this.nbLetters = 8;
                    this.hasSmallerLetters = true;
                    this.wordOfTheDay = this.words8Letters[index];
                    this.todaysGift = "un smartphone";
                    this.todaysBackground = 3;
                    break;
                case '2022-3-20':
                    index = 18318;
                    this.nbLetters = 8;
                    this.hasSmallerLetters = true;
                    this.wordOfTheDay = this.words8Letters[index];
                    this.todaysGift = "une TV";
                    this.todaysBackground = 3;
                    break;
                default:
                    this.isClosed = true;
            }
        },
        getSavedData() {
            if (localStorage.getItem('lastSave')) {
                const lastSave = localStorage.getItem('lastSave');
                if (lastSave === this.today.format('YYYY-M-D')) {
                    if (localStorage.getItem('attempts')) {
                        this.attempts = JSON.parse(localStorage.getItem('attempts'));
                    }
                    if (localStorage.getItem('results')) {
                        this.results = JSON.parse(localStorage.getItem('results'));
                    }
                    if (localStorage.getItem('currentAttempt')) {
                        this.currentAttempt = JSON.parse(localStorage.getItem('currentAttempt'));
                    }
                    if (localStorage.getItem('correctLetters')) {
                        this.correctLetters = JSON.parse(localStorage.getItem('correctLetters'));
                    }
                    if (localStorage.getItem('partialLetters')) {
                        this.partialLetters = JSON.parse(localStorage.getItem('partialLetters'));
                    }
                    if (localStorage.getItem('incorrectLetters')) {
                        this.incorrectLetters = JSON.parse(localStorage.getItem('incorrectLetters'));
                    }
                    if (localStorage.getItem('won')) {
                        this.won = JSON.parse(localStorage.getItem('won'));
                    }
                    if (localStorage.getItem('finished')) {
                        this.finished = JSON.parse(localStorage.getItem('finished'));
                    }
                    if (localStorage.getItem('userResults')) {
                        this.userResults = JSON.parse(localStorage.getItem('userResults'));
                    }
                } else {
                    this.reset();
                }
            } else {
                this.reset();
            }
        },
        reset() {
            this.setLSItem('attempts', JSON.stringify(this.attempts));
            this.setLSItem('results', JSON.stringify(this.results));
            this.setLSItem('currentAttempt', JSON.stringify(this.currentAttempt));
            this.setLSItem('correctLetters', JSON.stringify(this.correctLetters));
            this.setLSItem('partialLetters', JSON.stringify(this.partialLetters));
            this.setLSItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            this.setLSItem('won', JSON.stringify(this.won));
            this.setLSItem('finished', JSON.stringify(this.finished));
            this.setLSItem('lastSave', this.today.format('YYYY-M-D'));
            if (localStorage.getItem('userResults')) {
                this.userResults = JSON.parse(localStorage.getItem('userResults'));
            }
        },
        getKeyColor(key) {
            if (this.correctLetters.includes(key)) {
                return 'correct';
            } else if (this.partialLetters.includes(key)) {
                return 'partial';
            } else if (this.incorrectLetters.includes(key)) {
                return 'incorrect';
            } else {
                return '';
            }
        },
        handleKeyClick(key) {
            this.animateLetter = true;
            this.error = '';

            if (this.finished) return;

            if (key === 'Entrer') {
                this.verifyWord(this.attempts[this.currentAttempt - 1]);
            } else if (key === 'Suppr') {
                if(this.attempts[this.currentAttempt - 1].length > 0) {
                    this.attempts[this.currentAttempt - 1].pop();
                }
            } else if (this.attempts[this.currentAttempt - 1].length < this.nbLetters) {
                this.attempts[this.currentAttempt - 1].push(key);
            }
            this.setLSItem('attempts', JSON.stringify(this.attempts));
        },
        verifyWord(attempt) {
            if (attempt.length === this.nbLetters) {
                if (this[`words${this.nbLetters}Letters`].includes(attempt.join(''))) {
                    this.verifyLetters(attempt);
                } else {
                    this.error = 'Ce mot n\'est pas dans la liste';
                    window.setTimeout(() => {
                        this.error = '';
                    }, 1000);
                }
            } else {
                this.error = 'Veuillez entrer un mot de ' + this.nbLetters + ' lettres';
                window.setTimeout(() => {
                    this.error = '';
                }, 1000);
            }
        },
        verifyLetters(attempt) {
            let wordToGuess = this.wordOfTheDay.split('');
            let currentResult = this.results[this.currentAttempt - 1];
            
            attempt.forEach((letter, index) => {
                if (wordToGuess[index] === letter) {
                    currentResult[index] = 'correct';
                    wordToGuess[index] = '*';
                    if (!this.correctLetters.includes(letter)) {
                        this.correctLetters.push(letter);
                    }
                }
            });

            attempt.forEach((letter, index) => {
                if (currentResult[index] !== 'correct') {
                    if (wordToGuess.includes(letter)) {
                        let otherIndex = wordToGuess.indexOf(letter);
                        currentResult[index] = 'partial';
                        wordToGuess[otherIndex] = '*';
                        if (!this.partialLetters.includes(letter)) {
                            this.partialLetters.push(letter);
                        }
                    } else {
                        currentResult[index] = 'incorrect';
                        if (!this.incorrectLetters.includes(letter)) {
                            this.incorrectLetters.push(letter);
                        }
                    }
                }
            });
            this.setLSItem('results', JSON.stringify(this.results));
            this.setLSItem('correctLetters', JSON.stringify(this.correctLetters));
            this.setLSItem('partialLetters', JSON.stringify(this.partialLetters));
            this.setLSItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            if (attempt.join('') === this.wordOfTheDay) {
                this.won = true;
                this.finished = true;
                window.setTimeout(() => {
                    this.statsOpened = true;
                }, 2000);
            } else {
                this.currentAttempt++;
                if (this.currentAttempt > NB_ATTEMPTS) {
                    this.finished = true;
                    window.setTimeout(() => {
                        this.statsOpened = true;
                    }, 2000);
                }
            }
            this.setLSItem('currentAttempt', JSON.stringify(this.currentAttempt));
            this.setLSItem('won', JSON.stringify(this.won));
            this.setLSItem('finished', JSON.stringify(this.finished));
        },
        getWordID() {
            let day = this.today
            return day.clone().startOf('day').diff(FIRST_DAY, 'days') + 1
        },
        // Shitty function name to avoid shitty adblockers
        sh4reAntiAdblock() {
            const wordID = this.getWordID();

            const title = `#ParierCest N°${wordID}\n ${this.currentAttempt <= NB_ATTEMPTS ? this.currentAttempt : '❌' }/${NB_ATTEMPTS}\n`;
            let schema = this.results.slice(0, this.currentAttempt).map((result) => {
                return result.map((letter) => {
                    if (letter === 'correct') {
                        return '🟩';
                    } else if (letter === 'partial') {
                        return '🟨';
                    } else {
                        return '🟦';
                    }
                }).join('');
            }).join('\n');
            const url = "Toi aussi tente de trouver le mot du jour avec @parionssport : \n https://pariercest-parionssport.fr";

            let sharedContent = title + schema + '\n' + url;
            
            const errMsg = "Votre navigateur ne permet pas de copier du texte via un bouton."
            if (!navigator.clipboard || typeof navigator.clipboard.writeText !== 'function') {
                alert(errMsg);
                return;
            }

            navigator.clipboard.writeText(sharedContent).then(() => {
                this.resultsCopied = true;
                setTimeout(() => (this.resultsCopied = false), 10000);
            }).catch(() => alert(errMsg));
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
    }
}
</script>

<style lang="sass" scoped>
#game
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    overflow: hidden
    display: flex
    flex-direction: column
    background-image: url('~@/assets/img/backgrounds/background-1.jpeg')
    background-repeat: no-repeat
    background-position: center
    background-attachment: fixed
    background-size: cover
    &.background-2
        background-image: url('~@/assets/img/backgrounds/background-2.jpg')
    &.background-3
        background-image: url('~@/assets/img/backgrounds/background-3.jpg')
    header
        width: 100%
        height: 60px
        display: flex
        justify-content: center
        background: #020035
        position: relative
        z-index: 100
        @media (max-height: 540px)
            height: 40px
        .header-container
            max-width: 500px
            width: 100%
            height: 100%
            padding: 0 24px
            display: flex
            align-items: center
            justify-content: space-between
            @media (max-width: 512px)
                padding: 0 12px
                box-sizing: border-box
            .logo
                height: 120%
                @media (max-width: 512px)
                    height: 100%
                @media (max-width: 280px)
                    height: 40px
            .icon-btn
                display: flex
                align-items: center
                justify-content: center
                width: 32px
                height: 32px
                cursor: pointer
                @media (max-height: 540px)
                    width: 28px
                    height: 28px
                &.help
                    .icon
                        width: 20px
                &.share
                    .icon
                        width: 24px
    main
        width: 500px
        height: 95%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        position: relative
        .fade-enter-active, .fade-leave-active
            transition: opacity .5s
        .fade-enter, .fade-leave-to
            opacity: 0
        .fadeup-enter-active, .fade-leave-active
            transition: opacity .5s
        .fadeup-enter, .fade-leave-to
            opacity: 0
            transform: translateY(10px)
        .error
            top: 20px
            margin-top: 6px
            position: absolute
            background: #ebebeb
            color: #121213
            padding: 0.5em 1em
            border-radius: 0.5em
            font-size: 18px
            font-weight: bold
            z-index: 10
        .archives-selector
            margin: 12px 0
            color: white
            display: flex
            justify-content: space-around
            width: 100%
            .archives-arrow
                width: 16px
                height: 16px
                cursor: pointer
        .top
            padding: 20px 100px 0 100px
            height: 100%
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            background: url('~@/assets/img/grid-background.png') no-repeat center top -10px
            background-size: contain
            @media (max-width: 512px)
                padding: 20px 52px 0 52px
                width: 80%
            @media (max-height: 512px)
                padding: 10px 52px 0 52px
            .intro
                color: white
                font-family: 'Ferveur Compact Black Italic'
                font-size: 48px
                text-shadow: 0px 0px 16px rgba(0,0,0,0.8)
                margin-bottom: 22px
                &.closed
                    margin-top: 20%
                strong
                    color: #69D73C
                    position: relative
                    &::before
                        content: ''
                        display: block
                        width: 100%
                        height: 27px
                        background: url('~@/assets/img/underline.png') no-repeat center center
                        background-size: contain
                        position: absolute
                        bottom: -3px
                        left: -2%
                @media (max-height: 700px)
                    font-size: 38px
                    margin-bottom: 6px
                    strong::before
                        bottom: -6px
                @media (max-height: 512px)
                    font-size: 32px
                    margin-bottom: 3px
                    strong::before
                        bottom: -8px

            .grid
                max-width: 400px
                .attempt
                    display: flex
                    animation-duration: 0.3s
                    perspective: 800px
                    &.shake
                        animation-name: shake
            .sentence
                margin-top: 24px
                max-width: 350px
                font-family: 'Ferveur Compact Italic'
                color: white
                font-size: 20px
                strong
                    font-family: 'Ferveur Compact Black Italic'
                    font-size: 22px
                @media (max-height: 700px)
                    max-width: 220px
                    margin-top: 12px
                    font-size: 18px
                    strong
                        font-size: 20px
                @media (max-height: 512px)
                    max-width: 180px
                    font-size: 14px
                    margin-top: 8px
                    strong
                        font-size: 16px

        .keyboard
            margin-bottom: 20px
            background: #000013
            width: 100%
            @media (max-height: 900px)
                margin-bottom: 7px
            .keyboard-line
                margin-top: 10px
                &:first-child
                    margin: 0
                @media (max-height: 540px)
                    margin-top: 4px
        .help-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: flex-start
            align-items: center
            padding-top: 55px
            background-image: url('~@/assets/img/backgrounds/background-1.jpeg')
            background-repeat: no-repeat
            background-position: center
            background-attachment: fixed
            background-size: cover
            top: 0
            left: 0
            z-index: 10
            &.background-2
                background-image: url('~@/assets/img/backgrounds/background-2.jpg')
            &.background-3
                background-image: url('~@/assets/img/backgrounds/background-3.jpg')
            @media (max-height: 540px)
                padding-top: 40px
            .help-modal-content
                position: relative
                display: flex
                align-items: center
                flex-direction: column
                max-width: 450px
                width: 100%
                height: 100%
                box-sizing: border-box
                padding: 24px
                overflow-y: auto
                background: url('~@/assets/img/grid-background.png') no-repeat center top -10px
                background-size: contain
                @media (max-width: 470px)
                    background-size: cover
                @media (max-height: 580px)
                    padding: 24px 82px
                .close-btn
                    position: absolute
                    top: 12px
                    right: 56px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    cursor: pointer
                    transition: all .3s
                    @media (max-width: 470px)
                        right: 12px
                    @media (max-height: 540px)
                        top: 8px
                        right: 48px
                    @media (max-height: 580px)
                        right: 82px
                    .icon
                        height: 100%
                h2
                    font-family: 'Ferveur Compact Black Italic'
                    color: white
                    font-size: 32px
                    font-weight: 700
                    margin-bottom: 16px
                p
                    font-size: 18px
                    line-height: 1.3
                    margin-bottom: 12px
                    text-align: left
                    color: #8E8E90
                    color: white
                    font-family: 'Ferveur Compact Italic'
                    max-width: 350px
                    &:last-child
                        margin-bottom: 0
                    a
                        color: white
                        text-decoration: none
                        &:hover
                            text-decoration: underline
                    @media (max-height: 580px)
                        font-size: 14px
                .help-exemple
                    .help-word
                        display: flex
                        margin-top: 24px
                        margin-bottom: 8px
                        .help-letter-container
                            width: 36px
                            height: 36px
                            background: url(~@/assets/img/letters-backgrounds/white.png) no-repeat center center
                            background-color: white
                            background-size: cover
                            border-radius: 4px
                            box-sizing: border-box
                            margin: 2px
                            display: flex
                            align-items: center
                            justify-content: center
                            font-size: 22px
                            font-weight: bold
                            color: black
                            font-family: 'Ferveur Compact Italic'
                            box-shadow: 0px 0px 8px rgba(0,0,0,0.5)
                            &.correct
                                background: url(~@/assets/img/letters-backgrounds/green.png) no-repeat center center
                                background-size: cover
                                border-color: #00B200
                                background-color: #00B200
                                color: white
                            &.partial
                                background: url(~@/assets/img/letters-backgrounds/yellow.png) no-repeat center center
                                background-size: cover
                                border-color: #F8C50B
                                background-color: #F8C50B
                                color: white
                            &.incorrect
                                background: url(~@/assets/img/letters-backgrounds/blue.png) no-repeat center center
                                background-size: cover
                                border-color: #055CF0
                                background-color: #055CF0
                                color: white
                    p
                        span
                            font-weight: bold
                            &.correct
                                color: #00B200
                            &.partial
                                color: #F8C50B
        .endgame-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: flex-start
            align-items: center
            padding-top: 55px
            background-image: url('~@/assets/img/backgrounds/background-1.jpeg')
            background-repeat: no-repeat
            background-position: center
            background-attachment: fixed
            background-size: cover
            top: 0
            left: 0
            z-index: 10
            &.background-2
                background-image: url('~@/assets/img/backgrounds/background-2.jpg')
            &.background-3
                background-image: url('~@/assets/img/backgrounds/background-3.jpg')
            @media (max-height: 540px)
                padding-top: 40px
            .endgame-modal-content
                position: relative
                display: flex
                align-items: center
                flex-direction: column
                max-width: 450px
                width: 100%
                height: 100%
                box-sizing: border-box
                padding: 24px
                overflow-y: auto
                background: url('~@/assets/img/grid-background.png') no-repeat center top -10px
                background-size: contain
                @media (max-width: 470px)
                    background-size: cover
                @media (max-height: 580px)
                    padding: 24px 82px
                .close-btn
                    position: absolute
                    top: 12px
                    right: 56px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    cursor: pointer
                    transition: all .3s
                    @media (max-width: 470px)
                        right: 12px
                    @media (max-height: 540px)
                        top: 8px
                        right: 48px
                    @media (max-height: 580px)
                        right: 82px
                    .icon
                        height: 100%
                .soluce
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-top: 16px
                    width: 100%
                    .subtitle
                        font-size: 12px
                        font-weight: 700
                        color: rgba(255, 255, 255, 0.5)
                    h2
                        color: white
                        font-family: 'Ferveur Compact Black Italic'
                        font-size: 48px
                        text-shadow: 0px 0px 16px rgba(0,0,0,0.8)
                        @media (max-height: 540px)
                            font-size: 32px
                        strong
                            color: #69D73C
                            position: relative
                            &::before
                                content: ''
                                display: block
                                width: 100%
                                height: 27px
                                background: url('~@/assets/img/underline.png') no-repeat center center
                                background-size: contain
                                position: absolute
                                bottom: -3px
                                left: -2%
                                @media (max-height: 700px)
                                    bottom: -6px
                                @media (max-height: 540px)
                                    bottom: -8px
                        &.word-of-the-day
                            font-size: 62px
                            @media (max-height: 540px)
                                font-size: 48px
                    .too-bad
                        font-size: 26px
                        color: white
                        font-family: 'Ferveur Compact Italic'
                        max-width: 200px
                    .btn
                        display: flex
                        align-items: center
                        justify-content: center
                        width: 182px
                        height: 62px
                        border-radius: 4px
                        margin: 44px 0 6px 0
                        color: white
                        text-decoration: none
                        font-size: 28px
                        font-weight: 700
                        cursor: pointer
                        user-select: none
                        font-family: 'Ferveur Compact Black Italic'
                        box-shadow: 0px 0px 8px rgba(0,0,0,0.5)
                        @media (max-height: 540px)
                            font-size: 24px
                            width: 150px
                            height: 50px
                            margin: 12px 0 4px 0
                        p
                            padding-top: 5px
                        .icon
                            height: 1em
                            margin-left: 8px
                        // Shitty class name to avoid shitty adblocker
                        &.sh4re-btn-anti-adblock
                            background-color: #061A9D
                            &:hover
                                background-color: darken(#061A9D, 10%)
                            &:active
                                background-color: darken(#061A9D, 10%)
                .gifts
                    color: white
                    font-family: 'Ferveur Compact Italic'
                    margin-bottom: 64px
                    margin-top: 6px
                    .todays-gift
                        font-family: 'Ferveur Compact Black Italic'
                        font-size: 32px
                    .precisions
                        font-size: 11px
                    @media (max-height: 580px)
                        margin-bottom: 24px
                        font-size: 14px
                        .todays-gift
                            font-size: 24px
                .no-gifts
                    height: 62px
                .next-in, .time
                    font-size: 32px
                    color: white
                    margin-bottom: 2px
                    font-family: 'Ferveur Compact Black Italic'
    footer
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        position: relative
        z-index: 100
        .rules
            max-width: 500px
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 6px
            .legal-age-icon
                width: 24px
                @media (max-width: 622px)
                    width: 18px
            .rules-link
                color: white
                font-style: italic
                @media (max-width: 622px)
                    font-size: 12px
            div
                width: 24px
                @media (max-width: 622px)
                    width: 18px
        .legal
            padding: 3px 0
            width: 100%
            background: #000753
            color: white
            text-transform: uppercase
            font-family: Arial
            font-size: 8px
            @media (max-width: 512px)
                font-size: 6px
            @media (max-width: 340px)
                font-size: 5px
        
@keyframes shake
    0%
        transform: translateX(0)
    20%
        transform: translateX(-10px)
    40%
        transform: translateX(10px)
    60%
        transform: translateX(-10px)
    80%
        transform: translateX(10px)
    100%
        transform: translateX(0)
</style>
